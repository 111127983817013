
<template>
    <div class="supplementProtocol">
        <!-- AGT005：加盟商十严禁 -->
        <div class="content">
            <div class="title center bold mt3">加盟商十严禁</div>
            <div class="mt3">一、严禁以任何形式组织或参与非法集资、民间融资、高利贷、违规担保等活动。</div>
            <div class="mt3">二、严禁未经客户本人同意为其办理银行业务，严禁编造、虚构客户信息资料。</div>
            <div class="mt3">三、严禁代理客户保管存折、存单、银行卡、密码、身份证件、网银认证工具等。</div>
            <div class="mt3">四、严禁代替客户在各种凭条上签名及代替客户设置或修改密码。</div>
            <div class="mt3">五、严禁过度收集、窃取和泄露客户信息等违规行为。</div>
            <div class="mt3">六、严禁为客户打白条，不及时将客户资金入账。</div>
            <div class="mt3">七、严禁利用服务点支付终端等设备进行欺诈、套现等不正当交易。</div>
            <div class="mt3">八、严禁使用假币对外支付。</div>
            <div class="mt3">九、严禁借用村口科技合作方名义销售任何非村口科技合作方相关金融产品。</div>
            <div class="mt3">十、严禁参与黄、赌、毒活动及邪教组织。</div>
        </div>
        <div class="footer" v-show="signatureShow">
            <van-button type="info" color="#3F7C53" @click="signClick">签名</van-button>
        </div>
    </div>
</template>

<script>
    import { Button, Toast} from 'vant'
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name:'tenProhibitionProtocol',
        components:{
            [Button.name]: Button,
            [Toast.name]: Toast
        },
        data(){
            return{
                signatureShow: false,
                agreementParams: {}
            }
        },
        mounted(){
            this.$nextTick(()=>{
                this.signatureShow = this.$route.query.sign == 'sign' ? true : false
                if(this.$route.query.agreementParams){
                    this.agreementParams = JSON.parse(this.$route.query.agreementParams)
                }
            })
            uwStatisticAction('/agreement/tenProhibitionProtocol','附件三：加盟商十严禁')
        },
        methods:{
            signClick() {
                this.$router.push({name:'signatureFour', query:{stationNo: this.$route.query.stationNo}})
            }
        }
    }
</script>

<style lang="less" scoped>
    .supplementProtocol{
        background: #f5f5f5;
        font-size: 0.3rem;
        box-sizing: border-box;
        padding: 20px 0.3rem 100px 0.3rem ;
        -webkit-overflow-scrolling: touch;
        height: 100%;
        .content{
            .title{
                font-size: 0.4rem;
            }
            .bold{
                font-weight: bold
            }
            .center{
                text-align: center;
            }
            .indent5{
                text-indent: 0.6rem;
            }
            .mt3{
                margin-top: 0.3rem;
            }
            .underline{
                text-decoration: underline;
            }
        }
        .footer{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            .van-button{
                width: 100%;
            }
        }
    }
</style>
